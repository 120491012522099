@font-face {
  font-family: 'Circe';
  src: url('assets/fonts/Circe/Circe-Regular.eot');
  src: local('Circe'), local('Circe-Regular'),
  url('assets/fonts/Circe/Circe-Regular.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/Circe/Circe-Regular.woff') format('woff'),
  url('assets/fonts/Circe/Circe-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Circe';
  src: url('assets/fonts/Circe/Circe-Bold.eot');
  src: local('Circe Bold'), local('Circe-Bold'),
  url('assets/fonts/Circe/Circe-Bold.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/Circe/Circe-Bold.woff') format('woff'),
  url('assets/fonts/Circe/Circe-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), local('Montserrat-Medium'),
  url('assets/fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), local('Montserrat-Bold'),
  url('assets/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Circe', 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FAFAFA;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
